
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { InformacionComponentesFiles } from '@interface/files_conn_components'
import { NotificacionService } from './notificacion';
import { CookieUtil } from './cookies';
import { Globals } from '@config/global';
import { Router } from '@angular/router';
import alliedBank from './json/allied-banks.json'
import { openCDT } from './interface/open-cdt.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WidgetRate } from './interface/widget-rate.interface';
import { CheckParametersComponent } from '../modals/check-parameters/check-parameters.component';
import { BankNotAlliedComponent } from '../modals/bank-not-allied/bank-not-allied.component';
import { AlertConfirmComponent } from '../resources-alerts/alert-confirm/alert-confirm.component';

@Injectable({
  providedIn: 'root',
})

export class UtilsMethods_Services {

  public confirmProcessExitModaltext = {
    title: '¿Estás seguro de abandonar el proceso?',
    message: 'Si abandonas el proceso podrías perder el avance del formulario que no hayas guardado. Confirma que deseas salir del proceso:',
    textButtons: {
      confirm: {
        text: 'Cancelar',
        id: 'select_button_cancel_leave_form'
      },
      cancel: {
        text: 'Confirmar',
        id: 'select_button_confirm_leave_form'
      }
    }
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public globals_var: Globals,
    public breakpointObserver: BreakpointObserver,
    public router: Router,
    public cookies: CookieUtil,
    private notificacion_components: NotificacionService,
    private modal: NgbModal,
  ) {
  }
  /* Convertir un titulo en un path url */
  convertTitleInPath(title: string): string {
    // Convertir el texto a minúsculas
    const textoMin = title.toLowerCase();
    // Reemplazar caracteres especiales y tildes por sus equivalentes sin ellos
    const caracteresEspeciales: any = {
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
      'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u',
      'ä': 'a', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'ü': 'u',
      'ñ': 'n', 'ç': 'c', 'ß': 'ss'
    };
    let textoSinEspeciales = '';
    for (let i = 0; i < textoMin.length; i++) {
      const caracter = textoMin.charAt(i);
      const caracterSinEspecial = caracteresEspeciales[caracter] || caracter;
      textoSinEspeciales += caracterSinEspecial;
    }
    // Eliminar el apóstrofe solo si está seguido de una "s" y precedido por una letra o número
    const textoSinApostrofe = textoSinEspeciales
      .replace("’s", 's')
      .replace("?", "")
      .replace("¿", "")
      .replace(",", "");

    // Reemplazar los espacios en blanco por guiones
    const textoGuiones = textoSinApostrofe.replace(/\s+/g, '-');

    // Retornar el resultado
    return textoGuiones;
  }
  /* Detectar el modo de resoluciòn */
  detectScreenMode() {
    let resp;
    /* Dependiendo de la vista responsive, cargará cierta div padre de la vista */
    this.breakpointObserver.observe(["(min-width: 770px)"]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        //Entonces si el width es superior a 767, será la versión Desktop mostrando su propio diseño
        resp = true
      } else {
        //Entonces si el width es inferio a 767, será la versión Mobile mostrando su propio diseño
        resp = false
      }
    });

    return resp;
  }
  /* Obtener informaciòn del componente en archivos */
  GetSendInformationComponent_Files(): Promise<InformacionComponentesFiles> {
    return new Promise((resolve) => {
      this.notificacion_components.receiveNotificacion.subscribe((data: any) => {
        if (data.api === "send_information_to_componentes_process") {

          let body_income_support_uploaded = data?.data?.extra?.income_support_uploaded || false;
          let body_document_id_saved = data?.data?.identity_validation?.data?.document_id_saved || false;

          let resp: InformacionComponentesFiles = {
            bank_name: data.data.bank_name,
            has_restrictions: data.data.extra.has_restrictions,
            size_file: 0,
            sizeText: "",
            bank_id: data.data.bank_id,
            document_support_uploaded: body_income_support_uploaded,
            document_id_saved: body_document_id_saved
          };

          if (typeof resp.has_restrictions === "undefined") {
            resp.has_restrictions = false;
          }

          if (resp.bank_name === this.globals_var.FLAMINGO || resp.bank_name === this.globals_var.COLTEFINANCIERA) {
            resp.size_file = 20000000;
            resp.sizeText = "20MB";
          } else {
            resp.size_file = 20000000;
            resp.sizeText = "20MB";
          }

          resolve(resp);
        }
      });
    });
  }

  differenceDays(date_expiration: any): number {
    const fecha_actual = new Date();
    const fecha_expiration = new Date(date_expiration);

    // Convertir ambas fechas a milisegundos
    const tiempo_actual = fecha_actual.getTime();
    const tiempo_expiration = fecha_expiration.getTime();

    // Calcular la diferencia en milisegundos
    const diferencia_ms = tiempo_expiration - tiempo_actual;

    // Convertir la diferencia de milisegundos a días
    const diferencia_dias = Math.ceil(diferencia_ms / (1000 * 60 * 60 * 24));

    return diferencia_dias;
  }

  getRangeDate(dateFun: any, show: any): string {
    const date = new Date(dateFun);
    const day = date.getDate();
    const month = date.toLocaleString('es-ES', { month: 'short' });
    const year = date.getFullYear();

    if (show == 1) {
      return String(day);
    }
    if (show == 2) {
      return month;
    }
    if (show == 3) {
      return String(year);
    }

    return '';
  }

  //toma un número grande y lo convierte en una cadena abreviada.
  abbreviateLargeNumberToString(number: number, fraction: number) {
    if (isPlatformBrowser(this.platformId) && number !== undefined && number !== null) {
      let newValue: string = number.toString();
      if (number >= 1000) {
        // Definimos un arreglo de rangos de conversión
        const ranges = [
          { divider: 1, suffix: "" },
          { divider: 1e3, suffix: "K" },
          { divider: 1e6, suffix: "M" },
          { divider: 1e9, suffix: "B" },
          { divider: 1e12, suffix: "T" },
          { divider: 1e15, suffix: "P" },
          { divider: 1e18, suffix: "E" },
        ];

        // Inicializamos el índice en 0
        let index = 0;

        // Encontramos el rango adecuado para el número
        while (index < ranges.length - 1 && Math.abs(number) >= ranges[index + 1].divider) {
          index++;
        }

        // Redondeamos el valor dividiéndolo por el divisor del rango
        const roundedValue = (number / ranges[index].divider).toFixed(fraction);

        // Si el valor redondeado termina en ".0", eliminamos la parte decimal
        if (roundedValue.endsWith('.0')) {
          newValue = Math.floor(number / ranges[index].divider) + ranges[index].suffix;
        } else {
          // En caso contrario, conservamos la parte fraccional y el sufijo del rango
          newValue = roundedValue + ranges[index].suffix;
        }
      }
      // Manejamos el caso de 'NaN'
      if (newValue === 'NaN') {
        newValue = '***';
      }
      return newValue;
    }
    return
  };

  investmentProfit(amount: any, roi: any) {
    let value: any = parseInt(roi) - parseInt(amount);
    if (Number.isNaN(value)) {
      value = '***'
    }
    return value;
  }

  formatteDateCDTs(dateString: string): string {
    // Separar la fecha en partes
    const [year, month, day] = dateString.split('-');

    // Crear un array con los nombres de los meses
    const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    const monthIndex = parseInt(month, 10) - 1; // Convertir el mes en índice (0 basado)

    // Formatear la salida con HTML
    return `<span>${parseInt(day, 10)} de <b>${monthNames[monthIndex]}</b> de <b>${year}</b></span>`;
  }

  formatteDateToStringCompleted(date: Date | string) {
    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  
    let currentDate: Date;
  
    if (typeof date === 'string') {
      // Si es una cadena, intenta convertirla a un objeto Date usando la zona horaria UTC
      currentDate = new Date(date + 'T00:00:00Z');
    } else {
      // Si ya es un objeto Date, simplemente lo asignamos
      currentDate = date;
    }
  
    if (isNaN(currentDate.getTime())) {
      // Si no se pudo convertir la cadena a una fecha válida, devuelve un mensaje de error
      return 'Fecha no válida';
    }
  
    const dayOfWeek = daysOfWeek[currentDate.getUTCDay()];
    const dayOfMonth = currentDate.getUTCDate();
    const month = months[currentDate.getUTCMonth()];
    const year = currentDate.getUTCFullYear();
    return `${dayOfWeek}, ${dayOfMonth} de ${month} ${year}`;
  }

  formatDateWithTimeIncluded(fecha: Date | string) {
    if (isPlatformBrowser(this.platformId) && fecha !== undefined && fecha !== null) {
      const date = new Date(fecha);

      // Restar 5 horas a la hora
      date.setHours(date.getHours() - 5);

      const day = date.getDate();
      const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;

      return `${monthNames[monthIndex]} ${day} del ${year} - ${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
    }
    return ""
  }

  getQueryParam(query: any) {
    if (isPlatformBrowser(this.platformId)) {
      var url_search = window.location.search;
      var querys_params = new URLSearchParams(url_search);
      var param_resp = querys_params.get(query);
      return param_resp;
    }
    return;
  }

  convertNumberToText(number: any): string {
    const UNIDADES = ['', 'Uno', 'Dos', 'Tres', 'Cuatro', 'Cinco', 'Seis', 'Siete', 'Ocho', 'Nueve'];
    const DECENAS = ['', 'Diez', 'Veinte', 'Treinta', 'Cuarenta', 'Cincuenta', 'Sesenta', 'Setenta', 'Ochenta', 'Noventa'];
    const CENTENAS = ['', 'Ciento', 'Doscientos', 'Trescientos', 'Cuatrocientos', 'Quinientos', 'Seiscientos', 'Setecientos', 'Ochocientos', 'Novecientos'];
    const GRANDES_NUMEROS = [
      { limite: 1e12, singular: 'Billón', plural: 'Billones' },
      { limite: 1e9, singular: 'Mil millones', plural: 'Mil millones' },
      { limite: 1e6, singular: 'Millón', plural: 'Millones' },
    ];

    const convertirNumero = (num: number): string => {
      if (num === 0) {
        return 'Cero';
      }

      let palabras = '';

      if (num >= 100) {
        palabras += CENTENAS[Math.floor(num / 100)] + ' ';
        num %= 100;
      }

      if (num >= 10 && num < 20) {
        return palabras + DECENAS[num - 10];
      } else if (num >= 20) {
        palabras += DECENAS[Math.floor(num / 10)] + ' ';
        num %= 10;
      }

      if (num > 0) {
        palabras += UNIDADES[num];
      }

      return palabras.trim();
    };

    const convertirNumeroCompleto = (num: number): string => {
      if (num === 0) {
        return 'Cero';
      }

      let palabras = '';

      for (const grande of GRANDES_NUMEROS) {
        if (num >= grande.limite) {
          const parteEntera = Math.floor(num / grande.limite);
          palabras += convertirNumeroCompleto(parteEntera) + ' ' + (parteEntera > 1 ? grande.plural : grande.singular) + ' ';
          num %= grande.limite;
        }
      }

      if (num >= 1000) {
        const miles = Math.floor(num / 1000);
        palabras += convertirNumeroCompleto(miles) + ' mil ';
        num %= 1000;
      }

      if (num > 0) {
        palabras += convertirNumero(num);
      }

      return palabras.trim();
    };

    const montoATexto = parseInt(number, 10);
    return convertirNumeroCompleto(montoATexto);
  }

  calculateDays(dateLimitd: Date, dateCurrent: Date) {
    const oneDayMs = 24 * 60 * 60 * 1000;
    const timeDiff = dateLimitd.getTime() - dateCurrent.getTime();
    return Math.ceil(timeDiff / oneDayMs);
  }

  thousandsSeparator(value: any) {
    if (isPlatformBrowser(this.platformId) && value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }

  redondearAmount(amount: any) {
    return Math.round(amount);
  }

  _CloseModal(element: any) {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        let name: HTMLElement;
        if (element) {
          name = document.getElementsByClassName(element)[0] as HTMLElement;
          return name.click();
        }
      }, 200);
    }
  }

  //Convertir abreviatura de los montos
  convertNumberToShortString(n: any) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";

    return n;
  };

  updateCookies_Simulation(fullname: any, phone: any, amountValue: any) {
    this.cookies.save('amount', amountValue, 1);
    this.cookies.save('fullname', fullname, 1);
    this.cookies.save('phone-full', phone.e164Number, 1);
    this.cookies.save('phone-number', phone.number, 1);
  }

  scrollAnimation(target: string, duration: number, offset: number) {
    if (isPlatformBrowser(this.platformId)) {
      const targetElement: any = document.querySelector(target);
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition - offset;
      let startTime: any = null;

      function animation(currentTime: number) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easing(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      function easing(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      }

      requestAnimationFrame(animation);
    }
  }

  scrollTop() {
    const target = '#aplication-tubo-mcdt';
    const duration = 800;
    const offset = 10;
    this.scrollAnimation(target, duration, offset);
  }

  scrollToNewsMejorCDT(id: any) {
    const target = id;
    const duration = 1000;
    const offset = 60;
    this.smoothScroll(target, duration, offset);
  }

  /*
  Para una nueva promoción de Blue Days, por favor cambie los
  valores de la propiedad notificacion_params_blue_days y de la variable date_expired
  */
  isDayBlue() {
    let date_today:any = new Date();
    let date_expired:any = new Date('2024-12-16T15:00:00');
    let router_path = this.router.url.split('?')[0];
    let router_visibilty = [
      "/hoy-los-bancos-ofrecen",
      "/dias-azules",
      "/cdt-simulador",
      "/"
    ];

    if (date_today < date_expired) {
      let time_difference = date_expired.getTime() - date_today.getTime();
      let days_remaining = Math.ceil(time_difference / (1000 * 60 * 60 * 24));

      let notificacion_params_blue_days = {
        terms: [90, 180, 210, 300, 360, 540, 720],
        term_promo: [360],
        term_default: 360,
        rate_promo: 11.15,
        entity_name: this.globals_var.COLTEFINANCIERA,
        entity_id: this.globals_var.ID_COLTEFINANCIERA,
        amount: 250000,
        amount_minimum: 250000,
        date_expired: date_expired,
        range_months: '12 meses',
        last_day: false,
        applies_to_any_amount: false
      };

      if (days_remaining <= 3) {
        notificacion_params_blue_days.last_day = true;
      }

      this.notificacion_components.sendNotificacion({
        api: "send_information_blue_days_active",
        notificacion_params_blue_days
      });
      return true;
    } else {
      this.notificacion_components.sendNotificacion({
        api: "send_information_blue_days_false",
        router: this.router.url.replace(/\?.*$/, '')
      });
      return false;
    }
  }

  isCampaignMonth() {
    let date_today: any = new Date();
    let date_expired: any = '2024-09-26T08:00:00';
    let campaign_days = new Date(date_expired);

    if (date_today < campaign_days) {

      let time_difference = date_expired - date_today;
      let days_remaining = Math.ceil(time_difference / (1000 * 60 * 60 * 24));
      let notificacion_params_campaign_month = {
        date_expired: date_expired,
        last_day: false,
        background: "/images/web/campaign/cupido/cdt-cupido-campaign-agoto.png",
        logo: "cdt-cupido-y-bluedays.png",
        logo_full: '/images/web/slider/cdt-cupido-y-bluedays.png',
        title: "¡Este mes, el amor y la inversión a largo plazo, se premian!",
        description: "Para el amor: al abrir un CDT por 1 millón de pesos, participa por una noche grátis en un glamping. Y para la inversión: desde $20M tenemos una tasa especial para ti.",
        title_button: "Ver promoción",
        router_link: "/cdt-cupido",
        campaign: "cupido"
      }

      if (days_remaining <= 3) {
        notificacion_params_campaign_month.last_day = true;
      }

      this.notificacion_components.sendNotificacion({
        api: "send_information_campaign_month_active",
        notificacion_params_campaign_month
      });
      return true;
    } else {
      this.notificacion_components.sendNotificacion({
        api: "send_information_campaign_month_deactive",
        router: this.router.url.replace(/\?.*$/, '')
      });
      return false;
    }
  }

  isCampaignMonthReturnBool() {
    let date_today: any = new Date();
    let date_expired: any = '2024-09-26T08:00:00';
    let campaign_days = new Date(date_expired);

    if (date_today < campaign_days) {
      return true;
    } else {
      return false;
    }
  }

  isViewApp() {
    if (this.cookies.check('view')) {
      return true
    }
    return false
  }

  replaceNameBanks(bankname: string) {
    let bankname_replace = bankname
      .replace(this.globals_var.FLAMINGO, this.globals_var.COLTEFINANCIERA)
      .replace(this.globals_var.MIBANCO, 'Mibanco')
      .replace(this.globals_var.FINANCIERA_DANN_REGIONAL, this.globals_var.DANNREGIONAL)
      .replace('Banco Bogotá', this.globals_var.BOGOTA)
      .replace('Banco Davivienda', this.globals_var.DAVIVIENDA)
      .replace('Bancamía', this.globals_var.BANCAMIA)
      .replace('Coomeva', "Bancoomeva")
    return bankname_replace
  }

  replaceNameBanks_IMG(bankname: string) {
    let bankname_replace: string = this.replaceNameBanks(bankname)
      .replace('Mibanco', this.globals_var.MIBANCO)

    return bankname_replace
  }

  removeObjectsCompletedSatus(processes: any): any {
    const allowedStatus = [
      "LEAD-SIN-FORMULARIO",
      "CLIENTE-SUBIENDO-DOCUMENTOS",
      "DOCUMENTOS-SUBIDOS-VALIDANDO-PARA-FIRMA",
      "ENVIADOS-DOCUMENTOS-A-FIRMA",
      "DOCUMENTOS-FIRMADOS-ESPERANDO-SARLAF",
      "ESPERANDO-DEPOSITO",
      "ENVIADO-AL-BANCO-PARA-CREACION"
    ];

    if (processes) {
      processes = processes.filter((process: { process_status: string; }) => {
        return allowedStatus.includes(process.process_status);
      });
    }
    return processes;
  }

  removeAccents(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  scrollToSection(id: string) {
    if (isPlatformBrowser(this.platformId)) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  smoothScroll(target: string, duration: number, offset: number) {
    if (isPlatformBrowser(this.platformId)) {
      const targetElement: any = document.querySelector(target);
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition - offset;
      let startTime: any = null;

      function animation(currentTime: number) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easing(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      function easing(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      }

      requestAnimationFrame(animation);
    }

  }

  deleteSemicolons(value: string | number): string {
    if (isPlatformBrowser(this.platformId) && value !== undefined && value !== null) {
      if (typeof value === 'number') {
        value = value.toString();
      }
      return value.replace(/[.,]/g, '');
    }
    return ""
  }

  removeElementsWithZeroRateRoi(list: any[]): any[] {
    return list.filter((element) => element.rate !== 0 || element.roi !== 0);
  }

  isAlliedBank(bank: string) {
    if (alliedBank.includes(bank)) {
      return true
    }
    return false
  }

  openCDT(response: openCDT): void {
    const { bank, term, options, turbo } = response;

    const modalData = {
      bank: bank,
      place: term,
      data: this.removeElementsWithZeroRateRoi(options),
      turbo: turbo,
      step: 2
    };

    if (this.isAlliedBank(bank)) {
      this.openCDTModal(modalData);
    } else {
      this.openModalNotAllied(bank);
    }
  }


  openCDTModal(data: any): void {
    const modal = this.modal.open(CheckParametersComponent, {
      centered: true,
      size: 'lg paddingModal options_CheckParametros_ModalMobile',
      backdrop: 'static'
    });
    modal.componentInstance.CheckParametersDataModal = data;
  }

  openModalNotAllied(bankname: any): void {
    const modal = this.modal.open(BankNotAlliedComponent, {
      centered: true
    });
    modal.componentInstance.bankname_options = bankname;
  }

  validatePhone(inputPhone: any): { valid: boolean, phoneNumber: string, phoneFull: string } {
    return {
      valid: inputPhone.validate,
      phoneNumber: inputPhone.phone_number,
      phoneFull: this.deleteSpace(inputPhone.phone_full)
    };
  }

  cleanAmount(amount: string): string {
    return amount?.split('.').join('');
  }

  deleteSpace(text: string): string {
    if (text) {
      return text.replace(/\s+/g, '');
    }
    return text
  }

  orderBestBank_WidgetRate(order_term: number, results: any[]): WidgetRate[] {
    // Filtra los resultados que coinciden con el order_term
    const results_filter = results.filter(
      (response) => response.term === order_term
    );
    // Ordena los resultados filtrados por la propiedad rate de mayor a menor
    const results_order = results_filter.sort(
      (a, b) => b.rate - a.rate
    );
    // Retorna los primeros 3 resultados (o menos si no hay suficientes)
    return results_order.slice(0, 3);
  }

  getBestBank_SimulateInvestment(order_term: number, results: any[]): WidgetRate[] {
    // Filtra los resultados para el plazo de 360
    const results_for_term = results.filter(
      (response) => response.term === order_term
    );
    if (results_for_term.length === 0) {
      // No hay resultados para el plazo de 360
      return [];
    }
    // Ordena los resultados para el plazo de 360 por la propiedad rate de mayor a menor
    const best_results_term = results_for_term.reduce((max: any, current: any) =>
      current.rate > max.rate ? current : max
    );
    return best_results_term.bank_label;
  }

  reverseOptions(options: any) {
    return options.reverse();
  }

  onlyNumberKey(event: any) {
    // Obtener el carácter ingresado
    const inputChar = String.fromCharCode(event.charCode);

    // Utilizar una expresión regular para permitir solo caracteres numéricos y la tecla de retroceso
    const pattern = /^[0-9\b]+$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  confirmRefProcessExit(event: MouseEvent): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.globals_var.PATHS_CONFIRM_PROCESS_EXIT.includes(window.location.pathname)) {
        event.preventDefault();
        this.openAlertConfirmation(this.confirmProcessExitModaltext).then((result: boolean) => {
          if (!result) {
            window.location.href = (event.target as HTMLAnchorElement).href;
          }
        });
      }
    }
  }

  confirmNavigationProcessExit(route: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.globals_var.PATHS_CONFIRM_PROCESS_EXIT.includes(window.location.pathname)) {
        this.openAlertConfirmation(this.confirmProcessExitModaltext).then((result: boolean) => {
          if (!result) {
            this.router.navigateByUrl(route);
          }
        });
      } else {
        this.router.navigateByUrl(route);
      }
    }
  }

  openAlertConfirmation(modalData: { title: string, message: string }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const modalRef = this.modal.open(AlertConfirmComponent, { centered: true });
      modalRef.componentInstance.modalData = modalData;
      modalRef.componentInstance.getResult().subscribe((result: boolean) => {
        resolve(result);
      });
    });
  }

  removeDuplicateCookies(): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const allCookies = this.cookies.all();
        const cookieMap = new Map();

        allCookies.forEach(cookie => {
          if (cookieMap.has(cookie.name)) {
            const existingCookie = cookieMap.get(cookie.name);
            document.cookie = `${cookie.name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.mejorcdt.com; path=/`;
          }
        });
      } catch (error) {
        console.log('Error remove cookies duplicate: ', error)
      }
    }
  }

}

