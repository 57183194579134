<div>
    <div class="modal-body modal-sm">
        <div class="content-modal-theme">
            <div class="container-fluid px-0" id="">
                <div id="modalBody">
                    <button class="btn-home-hover position-absolute btn-close-bank-not-allied btn-close-modal fw-bold text-white" aria-label="Close" (click)="activeModal.dismiss()">
                        <i class="bi bi-x-lg fw-bold"></i>
                    </button>
                    <div id="">
                        <div class="row text-center py-3 px-2">
                            <div class="col-12 px-3">
                                <div class="w-100 ">
                                    <img loading="lazy"[defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="imageBankNotAllied" alt="" title="" class="img-thumbnail border-0">
                                </div>

                                <h2 class="text-blue-100 fw-bold mt-3">
                                    ¡Ups lo sentimos!
                                </h2>
                                <p class="mt-4 lh-tema text-justify">
                                    En este momento, no podemos conectarte con <b> {{ selectedBank }}  </b> . Sin embargo, contamos con <b>7 bancos aliados</b> que ofrecen tasas y rentabilidad superiores. <b>Simula tu inversión ahora</b> para conocer sus ofertas o contáctanos, y te ayudaremos a encontrar la mejor opción para ti.
                                </p>

                                <div class="row justify-content-between mx-0">
                                    <button class="btn bg-green text-white col-12 col-md-6">
                                        <a class="text-decoration-none text-white"  href="https://api.whatsapp.com/send?phone=573160234714&text=Hola%20estoy%20en%20la%20p%C3%A1gina%20de%20MejorCDT.com%20y%20quisiera%20mas%20informaci%C3%B3n">
                                            <i class="bi bi-whatsapp me-2"></i> Hablar con un asesor
                                        </a>
                                    </button>

                                    <button class="btn bg-blue-100 text-white col-12 col-md-5">
                                        <a class="text-decoration-none text-white" [routerLink]="['/cdt-simulador']" (click)="activeModal.dismiss()">
                                            Ir al simulador  <i class="bi bi-arrow-right ms-2"></i>
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>